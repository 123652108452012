import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24')
];

export const server_loads = [0,3];

export const dictionary = {
		"/": [~4],
		"/arena": [~5],
		"/arena/about": [6],
		"/arena/challenge/[slug]": [~7],
		"/arena/chat/[slug]": [~8],
		"/arena/leaderboard": [~9,[2]],
		"/arena/leaderboard/[slug]": [~10,[2]],
		"/authenticate": [~11],
		"/cancel": [12],
		"/chat/[id]": [~13],
		"/cygnet": [14],
		"/login": [15],
		"/maintenance": [16],
		"/manage": [17],
		"/paymentcomplete": [18],
		"/profile": [~19],
		"/register": [20],
		"/repe": [21,[3]],
		"/repe/create": [22,[3]],
		"/repe/manage": [~23,[3]],
		"/usage": [~24]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';